import React, { useCallback, useEffect, useState } from 'react';
import { getFirstAndLastNameFromFullName, getValidationIcons, validateStringValue } from '../../../utils/common';
import { isValidAadhaar, validatePAN } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { adhaarValidation, pancardValidation, uploadAddressProofBackAndFront, uploadImageToS3 } from '../../../actions/users';
import { VerifyAadharForm } from '../Modal/VerifyAadharForm';
import { openCustomModal } from '../../../actions/modal';
import { ISP, SP, SPISP } from '../../../utils/role';
import { useNavigate } from 'react-router-dom';
import { updateUserBussinessDetails, updateUserProfileDetails } from '../../../actions/onboarding';
import { spOnboard } from '../../../router/roleWisePageRoute';
import { businessForm } from '../../../router/constants';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import { useForm } from 'react-hook-form';
import Radio from '../../../common/Form/Radio';
import Upload from '../../../common/Form/FileUpload';
import ProfileUpload from '../../../common/Form/ProfileUpload';
import { defaultSingleChatPic } from '../../chat/ChatController';
import { aadhar as aadhaarKey } from '../../Modals/UploadAddressProof';
import config from '../../../config';
import developmentConfig from '../../../config/env/development';

export const sp = { value: 'sp', name: 'isSpOrIsp', label: 'Service Partner (SP)' };
export const isp = { value: 'isp', name: 'isSpOrIsp', label: 'Internet Broadband Provider ( ISP )' };
const radioOptions = [sp, isp];
const UPLOAD_ADDRESS_PROOF = 'UPLOAD_ADDRESS_PROOF';

export const ProfileDetailsForm = ({
    userProfileRef,
    setPreviousForm,
    previousForm
}) => {
    const { verifiedPanDetails, verifiedAadhaarDetails, isPanVerified, isAadharVerified } = useSelector((state) => state.user);

    const { isPancardVerificationLoading, isAadharValidationLoading, userProfileDetailsLoading, formButtonLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);


    const { is_aadhar_is_already_verified, aadharKycResponse, extractedImageData } = useSelector((state) => state.user);



    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showIcon, setShowIcon] = useState({ aadhaar: false, pancard: false });
    const [previewImage, setPreviewImage] = useState(null);
    const [previewPanImage, setPreviewPanImage] = useState(null);
    const [previewAadharFrontImage, setPreviewAadharFrontImage] = useState(null);

    useEffect(() => {
        if (verifiedPanDetails && verifiedPanDetails?.pan_number) {
            setShowIcon((prevState) => ({ aadhaar: prevState.aadhaar, pancard: true }))
        }
        if (is_aadhar_is_already_verified || (verifiedAadhaarDetails && verifiedAadhaarDetails?.aadhaar_number)) {
            setShowIcon((prevState) => ({ aadhaar: true, pancard: prevState.pancard }))
        }
    }, [verifiedPanDetails, verifiedAadhaarDetails, is_aadhar_is_already_verified, loggedUser?.address_proof_type, loggedUser?.address_proof_number, loggedUser?.pancard_number]);

    const defaultValues = {
        sp_name: '',
        email: '',
        pancard_number: '',
        sp_adhaar_number: '',
        isSpOrIsp: '',
        ...previousForm
    }

    const submitUserProfileDetails = (formValues) => {

        setPreviousForm(formValues);
        let type = SPISP.type;
        if (formValues.isSpOrIsp === isp.value) type = ISP.type;
        if (formValues.isSpOrIsp === sp.value) type = SP.type;
        let { first_name, last_name } = getFirstAndLastNameFromFullName(formValues.sp_name);
        let profilePayload = {
            email: formValues.email,
            type,
            first_name,
            last_name,
            navigate,
        };
        if (verifiedAadhaarDetails?.full_name) {
            let { gender, dob } = verifiedAadhaarDetails;
            profilePayload.dob = dob;
            profilePayload.gender = gender;
        }

        if (formValues.pancard_number) profilePayload.pancard_number = pancard_number;
        if (formValues.pancard_url) profilePayload.sp_pancard_image = formValues?.pancard_url;

        if (formValues.userProfileImage && formValues?.userProfileImage?.length > 0 && typeof formValues.userProfileImage === 'object' && (formValues.userProfileImage[0].name !== previousForm?.userProfileImage?.[0]?.name)) {
            profilePayload.profileImage = formValues?.userProfileImage;
        }
        dispatch(updateUserProfileDetails(profilePayload));

        if (previousForm?.email !== email) {
            if (formValues?.pancard_number || formValues?.sp_adhaar_number) {
                dispatch(updateUserBussinessDetails({ formValues, servicePartnerId: loggedUser?.service_partner_id, navigate, redirect: false }));
            }
            if (formValues.sp_adhaar_number && formValues.sp_adhaar_image && formValues.sp_adhaar_image_back) {
                let payload = {
                    addressProofType: 'Aadhar',
                    addressProofNumber: formValues.sp_adhaar_number,
                    proofImage: formValues.aadhaar_front_url,
                    closeMoadal: false,
                    fileUrlBack: formValues.sp_adhaar_image_back[0],
                    formName: UPLOAD_ADDRESS_PROOF,
                    frontAdharUrl: extractedImageData?.aadhaar_front_url
                }
                dispatch(uploadAddressProofBackAndFront(payload))

            }
        } else {
            navigate(`${spOnboard.path}?step=${businessForm.path}`);
        }
    };

    const methods = useForm({
        defaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange'
    });
    const { watch, setValue } = methods;

    const pancard_number = watch('pancard_number');
    const sp_adhaar_number = watch('sp_adhaar_number');
    const email = watch('email');
    const panValue = watch('pancard_number');

    useEffect(() => {
        let sp_name = ``;
        if (verifiedAadhaarDetails?.full_name && sp_adhaar_number) sp_name = verifiedAadhaarDetails?.full_name;
        else if (verifiedPanDetails?.full_name && pancard_number && isPanVerified) sp_name = verifiedPanDetails?.full_name;
        if (sp_name) setValue('sp_name', sp_name)
        let dob = ''
        if (verifiedAadhaarDetails?.dob) dob = verifiedAadhaarDetails?.dob
        if (dob) setValue('dob', dob)
    }, [isPanVerified, pancard_number, verifiedPanDetails?.full_name, sp_adhaar_number, verifiedAadhaarDetails?.full_name, setValue, verifiedAadhaarDetails?.dob]);



    const prePopulateDataWithAuth = useCallback(() => {
        if (loggedUser.type === SPISP.type) {
            setValue('isSp', true);
            setValue('isIsp', true);
        } else if (loggedUser.type === SP.type) {
            setValue('isSpOrIsp', sp.value);
        } else if (loggedUser.type === ISP.type) {
            setValue('isSpOrIsp', isp.value);
        }

        let name = '';
        if (loggedUser.first_name || loggedUser.last_name) {
            name = `${loggedUser.first_name} ${loggedUser.last_name}`;
            setValue('sp_name', name);
        }

        if (loggedUser.email) {
            setValue('email', loggedUser.email);
        }
        if (loggedUser?.address_proof_url_back) {
            setValue('sp_adhaar_image_back', loggedUser?.address_proof_url_back)
        }
        if (validatePAN(loggedUser.pancard_number)) {
            setValue('pancard_number', validateStringValue(loggedUser.pancard_number));
            setShowIcon((prevState) => ({ aadhaar: prevState.aadhaar, pancard: true }));

        }
        if (loggedUser?.address_proof_url_back) {
            setValue('sp_adhaar_image_back', loggedUser?.address_proof_url_back)
        }
        if (loggedUser.date_of_birth) {
            setValue('dob', validateStringValue(loggedUser.date_of_birth));
        }
        if (isValidAadhaar(loggedUser.address_proof_number) && loggedUser.address_proof_type === aadhaarKey.value) {
            setValue('sp_adhaar_number', (loggedUser.address_proof_number));
            setShowIcon((prevState) => ({ aadhaar: true, pancard: prevState.pancard }));
        }
    }, [
        loggedUser.type,
        loggedUser.address_proof_number,
        loggedUser.pancard_number,
        loggedUser.email,
        loggedUser.first_name,
        loggedUser.last_name,
        setValue,
        loggedUser?.date_of_birth,
        loggedUser.address_proof_type
    ]);

    useEffect(() => {
        prePopulateDataWithAuth();
    }, [prePopulateDataWithAuth]);

    const isExtractImageLoading = formButtonLoading?.PAN_PROOF || formButtonLoading?.ADDRESS_PROOF;

    const onPanUpload = (event) => {
        if (event && event[0]) {
            // Reset PAN related fields and verification status
            setValue('pancard_number', '');
            setValue('pancard_url', '');
            setValue('sp_name', '');  // Reset name if it came from PAN
            // Reset verification status in redux store
            dispatch({ type: 'RESET_PAN_VERIFICATION' });
            dispatch(uploadImageToS3({ file: event[0], user_id: loggedUser.id, reason: 'PAN_PROOF' }));
        }
    };

    const onAadharFrontUpolad = (event) => {
        if (event && event[0]) {
            // Reset Aadhar related fields
            setValue('sp_adhaar_number', '');
            setValue('sp_name', '');  // Reset name if it came from Aadhar
            setValue('dob', '');  // Reset DOB as it comes from Aadhar
            dispatch(uploadImageToS3({
                file: event[0],
                addressProofType: 'Aadhar',
                user_id: loggedUser.id,
                reason: 'ADDRESS_PROOF'
            }));
        }
    };



    const onPanChange = useCallback((value) => {
        const isValid = validatePAN(value);
        if (isValid) {
            dispatch(pancardValidation({
                pan_number: value,
                isBussinessPancard: false
            }));
        }
    }, [dispatch]);

    let isPanValid
    useEffect(() => {
        if (extractedImageData?.pan_number || panValue) {

            onPanChange(panValue || extractedImageData?.pan_number);
            isPanValid = validatePAN(extractedImageData?.pan_number || panValue);
        }
    }, [extractedImageData?.pan_number, onPanChange, panValue]);
    const verifyAadhar = useCallback(
        (sp_adhaar_number) => {
            let modalWidth = '40rem',
                modalHeight = 'auto';
            let modalComponent = <VerifyAadharForm aadhar_number={sp_adhaar_number} />;
            let head = 'Verify OTP';
            dispatch(openCustomModal({ modalComponent, heading: head, modalWidth, modalHeight }));
        },
        [dispatch]
    );

    useEffect(() => {
        if (isValidAadhaar(sp_adhaar_number) && aadharKycResponse && !loggedUser.address_proof_number && !userProfileDetailsLoading && !formButtonLoading?.[UPLOAD_ADDRESS_PROOF] && !isAadharValidationLoading) {
            verifyAadhar(sp_adhaar_number);
        }
    }, [verifyAadhar, sp_adhaar_number, aadharKycResponse, is_aadhar_is_already_verified, loggedUser.address_proof_type, loggedUser.address_proof_number]);

    const onAadharChange = useCallback((value) => {
        let payload = { aadhaar_number: value };
        if (config.name === developmentConfig.name) {
            if (value === '111111111111') {
                payload.already_verified = true;
            }
            else payload.already_verified = false;
        }
        dispatch(adhaarValidation(payload));
    }, [dispatch, isAadharVerified]);

    useEffect(() => {
        if (extractedImageData && extractedImageData.is_valid_pan) {
            const panNumber = extractedImageData.pan_number;
            setValue('pancard_number', panNumber);
            setValue('pancard_url', extractedImageData.pan_url);
            if (panNumber && panNumber !== pancard_number) {
                onPanChange(panNumber);
            }
        }

        if (extractedImageData && extractedImageData.is_valid_adhaar) {
            let aadhaarNumber = extractedImageData.adhaar_number.replace(/\s+/g, '');
            setValue('sp_adhaar_number', aadhaarNumber);
            if (!isAadharVerified) {
                onAadharChange(aadhaarNumber);
            }
        }
        if (extractedImageData && extractedImageData.date_of_birth) {
            setValue('dob', extractedImageData.date_of_birth);
        }
        if (extractedImageData && extractedImageData.name_on_adhaar_or_pan) {
            setValue('sp_name', extractedImageData.name_on_adhaar_or_pan);
        }

    }, [extractedImageData, setValue, onAadharChange, isAadharVerified, pancard_number,]);


    const is_aadhaar_verified = isAadharVerified || (loggedUser.address_proof_type === aadhaarKey.value && isValidAadhaar(loggedUser.address_proof_number));
    const is_pancard_verified = isPanVerified || loggedUser?.pancard_number;


    useEffect(() => {
        const loadImages = async () => {
            // Handle Aadhar Back Image
            if (loggedUser?.address_proof_url_back) {
                try {
                    const response = await fetch(loggedUser.address_proof_url_back);
                    const blob = await response.blob();
                    const file = new File([blob], 'address_proof_back.jpg', {
                        type: response.headers.get('content-type') || 'image/jpeg'
                    });
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(file);
                    setValue('sp_adhaar_image_back', dataTransfer.files);
                    const imageUrl = URL.createObjectURL(blob);
                    setPreviewImage(imageUrl);
                } catch (error) {
                    console.error('Error loading address proof back:', error);
                }
            }

            // Handle Aadhar Front Image
            if (loggedUser?.address_proof_url) {
                try {
                    const response = await fetch(loggedUser.address_proof_url);
                    const blob = await response.blob();
                    const file = new File([blob], 'address_proof_front.jpg', {
                        type: response.headers.get('content-type') || 'image/jpeg'
                    });
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(file);
                    setValue('sp_adhaar_image', dataTransfer.files);
                    const imageUrl = URL.createObjectURL(blob);
                    setPreviewAadharFrontImage(imageUrl);
                } catch (error) {
                    console.error('Error loading address proof front:', error);
                }
            }

            // Handle PAN Card Image
            if (loggedUser?.pancard_url) {
                try {
                    const response = await fetch(loggedUser.pancard_url);
                    const blob = await response.blob();
                    const file = new File([blob], 'pan_card.jpg', {
                        type: response.headers.get('content-type') || 'image/jpeg'
                    });
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(file);
                    setValue('sp_pancard_image', dataTransfer.files);
                    const imageUrl = URL.createObjectURL(blob);
                    setPreviewPanImage(imageUrl);
                } catch (error) {
                    console.error('Error loading PAN card:', error);
                }
            }
        };

        loadImages();
    }, [loggedUser?.address_proof_url_back, loggedUser?.address_proof_url, loggedUser?.pancard_url, setValue]);


    return (
        <div className='slide-right overflow-x-hidden'>
            <Form
                className='w-full'
                methods={methods}
                onSubmit={submitUserProfileDetails}
                submitRef={userProfileRef}
            >
                <div>
                    <div className='profile-pic relative mx-auto'>
                        <div className='max-w-max max-h-max mx-auto flex flex-row justify-center relative'>
                            <ProfileUpload name='userProfileImage' id='userProfileImage' required={!loggedUser.profile_picture} accept='image/png, image/gif, image/jpeg'
                                validateFileType={(file) => {
                                    const isImage = ['image/jpeg', 'image/png', 'image/gif'].includes(file.type);
                                    return isImage || `${file.name} is not an image`;
                                }} defaultPic={loggedUser.profile_picture ? loggedUser.profile_picture : defaultSingleChatPic} />
                        </div>
                    </div>
                </div>
                <div className=' flex items-center gap-4 '>
                    <Radio readOnly={!['', undefined, null].includes(loggedUser.type)} name='isSpOrIsp' className='flex items-center gap-2' type='radio' options={radioOptions} label='I am joining as' required />
                </div>
                <div className='flex flex-col'>

                    <>
                        <div className='flex'>
                            <div className='w-[50%]'>
                                <Upload
                                    label='Upload Pancard Image'
                                    name='sp_pancard_image'
                                    required={!is_pancard_verified}
                                    onChange={onPanUpload}
                                />

                            </div>
                            <Input
                                name='pancard_number'
                                required
                                label='Personal/Business(PAN Card Number)'
                                className={'w-[50%]'}
                                onChange={(event) => {

                                    onPanChange(event.target.value);
                                }}

                                validate={(pancard) => {
                                    if (pancard === '') return 'Field cannot be empty';
                                    return validatePAN(pancard) || 'Invalid PAN Number';
                                }}
                                isLoading={isPancardVerificationLoading}
                                readOnly
                                onBlur={() => methods.trigger('pancard_number')}
                                iconRight={isPanVerified ? {
                                    hoverTitle: 'PAN Verified',
                                    name: getValidationIcons(true)
                                } : null}
                            />
                        </div>
                        {<div className='flex'>
                            <div className='w-full'>
                                <Upload
                                    label='Upload Aadhar Front Image'
                                    name='sp_adhaar_image'
                                    required={!is_aadhaar_verified}
                                    onChange={onAadharFrontUpolad}
                                />

                            </div>
                            <div className='w-full'>
                                <Upload
                                    label='Upload Aadhar Back Image'
                                    name='sp_adhaar_image_back'
                                    required={!loggedUser?.address_proof_url_back}
                                // onChange={onAadharBackUpload}
                                />

                            </div>
                        </div>}
                    </>
                </div>
                <div className='flex'>
                    <Input
                        label='Address Proof (Aadhaar Number)'
                        name='sp_adhaar_number'
                        readOnly
                        required
                        className={'w-full'}
                        iconRight={isAadharVerified ? {
                            hoverTitle: 'Aadhaar Verified',
                            name: getValidationIcons(true)
                        } : null}
                        isLoading={isAadharValidationLoading}
                        maxLength={12}
                    />
                    <Input
                        label='Email Address'
                        name='email'
                        required
                        className={'w-full text-left'}
                        validate={(email) => {
                            if (email === '') return true;
                            return (
                                email.match(
                                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                ) || 'Invalid Email'
                            );
                        }}
                    />
                </div>
                <div className='flex'>


                    <Input label='Full Name' readOnly name='sp_name' maxLength={30} required className={'w-full text-left'} value={extractedImageData?.name_on_adhaar_or_pan} />
                    <Input label='Your date of birth ' name='dob' readOnly className={'w-full'} required value={extractedImageData?.date_of_birth} />
                </div>

            </Form>
            <div className='flex justify-end '>
                {((verifiedAadhaarDetails?.dob) && ((!verifiedAadhaarDetails.isPersonAdult))) && <div className='text-scogoclosed flex h-16  text-font19 w-[33%] font-medium'>You are under 18  </div>}
            </div>
            {isExtractImageLoading ? <FullScreenLoader /> : null}
        </div >
    );
};

export const isPerson18YearOld = (dateString) => {
    const birthDate = new Date(dateString)
    const currentDate = new Date()
    let age = currentDate.getFullYear() - birthDate.getFullYear()
    if (currentDate.getMonth() < birthDate.getMonth() || (currentDate.getMonth() === birthDate.getDate() && currentDate.getDate() < birthDate.getDate())) {
        age--
    }
    return age >= 18
};


const FullScreenLoader = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50">
            <div className="loader">
                <svg className="animate-spin h-8 w-8 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
        </div>
    );
};
