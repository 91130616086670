import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SkeltonCardOne } from '../../../common/SkeltonCardOne';
import { getTicketPayments, cleanupTicketPaymentDetails } from '../../../actions/tickets';
import GridTable from '../../../common/GridTable';
import { GetPaymentsListLogic } from '../PaymentController';
import moment from 'moment';
import OverflowTip from '../../../common/OverflowTip';
import { validateDateValue, validateNumberValue } from '../../../utils/common';
import { paymentType } from '../../Wallet/walletController';
import StatusLabel from '../reusableComponents/StatusLabel';
import IconToolTip from '../../../common/IconToolTip';
import { copyToClipboard } from '../../../actions/utils';


const PaymentsDetails = ({ ticketId, user_type = 'ALL', ticket }) => {
    const { isTicketPaymentLoading } = useSelector((state) => state.loading);
    const { ticketPayment, ticketBasePayment } = useSelector((state) => state.tickets);
    const { loggedUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [transformedPaymentData, setTransformedPaymentData] = useState([]);

    const isToShowBasePaymentColumnInTicketPaymentDetails = GetPaymentsListLogic('isToShowBasePaymentColumnInTicketPaymentDetails');

    useEffect(() => {
        if (!isNaN(ticketId) && ticketId > 0) {
            dispatch(getTicketPayments({ ticketId, incentive: true, user_type }));
        }
        return () => dispatch(cleanupTicketPaymentDetails());
    }, [ticketId, dispatch]);

    useEffect(() => {
        let data = [];
        if (!['', undefined, null].includes(ticketBasePayment) && isToShowBasePaymentColumnInTicketPaymentDetails) {
            let obj = {
                transactionNo: ticketBasePayment?.scogo_transaction_no ? ticketBasePayment?.scogo_transaction_no : '',
                user: `${ticketBasePayment?.vendor?.first_name ? ticketBasePayment.vendor.first_name : ''} ${ticketBasePayment?.vendor?.last_name ? ticketBasePayment.vendor.last_name : ''
                    } (partner)`,
                basePaymentDetails: 'BASE PAYMENT',
                orderAmount: `${ticketBasePayment?.amount ? ticketBasePayment?.amount : 0}`,
                status: ticketBasePayment?.is_request_raised === 4 ? 'Success' : ticketBasePayment?.is_request_raised === 3 ? 'Rejected' : 'Pending',
                payment_ratio: 1
            };
            data.push(obj);
        }

        Array.isArray(ticketPayment) &&
            ticketPayment.forEach((transaction) => {
                let currentTransaction = {
                    transactionNo: transaction.scogo_tx_no,
                    orderStatus: transaction.orderStatus,
                    createdBy: transaction.createdBy,
                    paymentType: transaction.paymentType,
                    user: `${transaction?.user?.first_name ? transaction.user.first_name : ''} ${transaction?.user?.last_name ? transaction.user.last_name : ''}`,
                    paymentMode: transaction?.paymentMode,
                    reason: transaction.reason,
                    txMsg: transaction.txMsg,
                    txTime: transaction.txTime,
                    orderAmount: transaction.orderAmount,
                    status: transaction.status,
                    processedByDetails: transaction.processedByDetails,
                    processed_at: transaction.processed_at,
                    recepient: transaction.recepient,
                    sender: transaction.sender,
                    fk_user_id: transaction.fk_user_id,
                    payment_ratio: transaction?.user?.payment_ratio ? transaction?.user?.payment_ratio : 1,
                    referenceId: transaction?.referenceId,
                    created_at: transaction.created_at,
                    txCharges: validateNumberValue(transaction?.txCharges)
                };
                data.push(currentTransaction);
            });

        setTransformedPaymentData(data);
    }, [ticketPayment, ticketBasePayment, isToShowBasePaymentColumnInTicketPaymentDetails]);

    const StatusBox = ({ status }) => {
        const classLookup = {
            Approved: 'scogo2e',
            Failure: 'scogoclosed',
            Scheduled: 'scogoorange',
            Pending: 'scogoorange',
            Rejected: 'scogoclosed',
            Success: 'scogo2e',
        };
        return <StatusLabel content={status} color={classLookup[status]} />;
    };

    const getTransformedStatus = (status, paymentType, orderStatus) => {
        let label = 'Pending';
        if (['WITHDRAW', 'BANK_TRANSFER_CREDIT'].includes(paymentType)) {
            if (status === '200' && ['15', 15].includes(orderStatus)) {
                label = 'Success';
            } else if (['17', 17].includes(orderStatus)) {
                label = 'Rejected';
            } else {
                label = 'Pending';
            }
        } else if (status === 'Success') {
            label = 'Approved';
        } else if (status === 'Failure') {
            label = 'Rejected';
        } else if (status === 'Scheduled') {
            label = 'Scheduled';
        }
        return label;
    };

    const isToShowPaymentStatus = (payment) => {
        return ['Scheduled', 'Success', 'Failure', '200'].includes(payment.status) && (payment.processedByDetails || payment.createdBy);
    }

    const isValidPaymentProcessedDate = (date) => validateDateValue(date);

    const getOrderAmount = (payment) => {
        let amount = '';
        let text = '';
        let isUsePaymentRatio = false;
        const isUserIsSender = payment.sender === payment.fk_user_id;
        const isUserIsReciever = payment.recepient === payment.fk_user_id;
        if (['SERVICE'].includes(payment.reason) && payment.status !== 'Success') {
            isUsePaymentRatio = true;
        }
        if (payment.paymentType === paymentType.incentive || [paymentType.withdraw, paymentType.bank_transfer_credit].includes(payment.paymentType)) {
            amount = `${payment.orderAmount}`;
        } else {
            if (payment.paymentMode === 'CUSTOMER') {
                amount = `${!isUserIsSender ? '-' : ''}${payment.orderAmount}`;
            } else {
                amount = `${!isUserIsReciever ? '-' : ''}${isUsePaymentRatio ? payment.orderAmount * payment.payment_ratio : payment.orderAmount}`;
            }
        }

        if (['INVOICE'].includes(payment.reason)) {
            amount = `${validateNumberValue(amount) + validateNumberValue(payment.txCharges)}`;
            text = '(Inc GST)'
        }
        return { amount, text };
    };

    const copyTransaction = data => {
        let content = '';
        let sender = 'Scogo';
        if (loggedUser.manage_partner_payments === 1 && loggedUser.customer_company_name) {
            sender = loggedUser.customer_company_name;
        }

        let payee = 'You';
        if (data.user) payee = data.user;

        content = '';
        content += `${sender} has paid ${payee}\n`;
        content += `Amount: ${data.orderAmount}\n`;
        content += `Mode of Payment: ${data.paymentMode}\n`;
        content += `${sender} Transaction No: ${data.transactionNo}\n`;
        if (ticket && ticket.job_ticket_number) content += `Ticket Number: ${ticket.job_ticket_number}\n`;
        content += `Transaction Time: ${moment(data.txTime).add(5.50, 'hours').format('DD-MM-YYYY HH:mm:ss')}\n`;
        if (data.txMsg) {
            content += `Remark: ${data.txMsg}`;
        }
        dispatch(copyToClipboard({ data: content }))
    }
    const copyTransactionInvoice = data => {
        let content = '';
        let sender = 'Scogo';
        if (loggedUser.manage_partner_payments === 1 && loggedUser.customer_company_name) {
            sender = loggedUser.customer_company_name;
        }

        let payee = 'You';
        if (data.user) payee = data.user;

        content = '';
        content += `${sender} has paid ${payee}\n`;
        content += `Amount: ${data.orderAmount}\n`;
        content += `Mode of Payment:  VENDOR\n`;
        content += `${sender} Invoice No: ${data.referenceId}\n`;
        if (ticket && ticket.job_ticket_number) content += `Ticket Number: ${ticket.job_ticket_number}\n`;
        content += `Transaction Time: ${moment(data.txTime).add(5.50, 'hours').format('DD-MM-YYYY HH:mm:ss')}\n`;
        if (data.txMsg) {
            content += `Remark: ${data.txMsg}`;
        }
        dispatch(copyToClipboard({ data: content }))
    }

    const bluePrint = {
        table: [
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Transaction No',
                },
                body: {
                    render: (payment) => {
                        return (
                            <div>
                                <OverflowTip textClass={'text-scogoprimary font-medium cursor-pointer'} someLongText={payment.transactionNo} />
                                {payment.createdBy && payment.paymentType !== 'INCENTIVE' ? (
                                    <span className='w-full flex items-center gap-2'>
                                        <p className='text-medium'>Requested by:</p>
                                        <OverflowTip textClass='font-medium' someLongText={`${payment.createdBy.first_name} ${payment.createdBy.last_name}`} />
                                    </span>
                                ) : (
                                    <></>
                                )}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'User',
                },
                body: {
                    render: (payment) => {
                        return (
                            <div>
                                <OverflowTip someLongText={payment.user}></OverflowTip>
                                {payment.paymentMode ? <b>({payment.paymentMode.toLowerCase()})</b> : ''}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Details',
                },
                body: {
                    render: (payment) => {
                        return <PaymentColumn payment={payment} />;
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Amount',
                },
                body: {
                    render: (payment, rowIndex) => {
                        const { amount, text } = getOrderAmount(payment)
                        return <p className='truncate w-full'>₹ {amount}
                            <br />
                            {text && <span>{text}</span>}
                        </p>;
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Status',
                },
                body: {
                    render: (payment, rowIndex) => {
                        let status = getTransformedStatus(payment?.status, payment?.paymentType, payment?.orderStatus);
                        const showCopyIcon = ['WITHDRAW', 'BANK_TRANSFER_CREDIT',].includes(payment.paymentType);
                        return (
                            <div>
                                <div className='flex'>
                                    <StatusBox status={status} />
                                    {showCopyIcon && <IconToolTip placement={'bottom'} title={`Copy Serial Number`}>
                                        <span className='hover:text-scogoorange cursor-pointer material-icons text-scogobgsky text-lg pl-1' onClick={() => copyTransaction(payment)}>
                                            content_copy
                                        </span>
                                    </IconToolTip>}
                                    {payment.reason === 'INVOICE' && <IconToolTip placement={'bottom'} title={`Copy Serial Number`}>
                                        <span className='hover:text-scogoorange cursor-pointer material-icons text-scogobgsky text-lg pl-1' onClick={() => copyTransactionInvoice(payment)}>
                                            content_copy
                                        </span>
                                    </IconToolTip>}
                                </div>
                                {isToShowPaymentStatus(payment) && (
                                    <div className='pt-2'>

                                        {payment?.processedByDetails ? <small>
                                            by <b>{`${payment?.createdBy?.first_name} ${payment?.createdBy?.last_name}`}</b>
                                        </small> : <small>
                                            by <b>{`${payment?.createdBy?.first_name} ${payment?.createdBy?.last_name}`}</b>
                                        </small>}
                                        {(
                                            <div className='truncate w-4/5'>
                                                <OverflowTip
                                                    someLongText={
                                                        payment?.processed_at ?
                                                            <small>
                                                                On <b>{`${moment(payment?.processed_at).format('DD-MM-YYYY HH:mm:ss')}`}</b>
                                                            </small> :
                                                            <small>
                                                                On <b>{`${moment(payment?.created_at).format('DD-MM-YYYY HH:mm:ss')}`}</b>
                                                                {/* Timestamp <b>{`${moment(payment?.created_at).format('DD-MM-YYYY HH:mm:ss')}`}</b> */}
                                                            </small>
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    },
                },
            },
        ],
    };

    if (isTicketPaymentLoading) return <SkeltonCardOne />;

    return (
        <div className='px-7 pb-8 py-4'>
            <p className='text-medium text-md'>
                <b>*</b>All payments from Scogo are made post deduction of TDS and System Charges
            </p>
            <GridTable schema={bluePrint} rowData={transformedPaymentData} tableBodyHeight={'auto'} tableError='No Ticket Found' />
        </div>
    );
};

export default PaymentsDetails;

const PaymentColumn = ({ payment }) => {

    const { basePaymentDetails, paymentType } = payment;
    const isBasePayment = basePaymentDetails === 'BASE PAYMENT';
    const isIncentivePaymentType = paymentType === 'INCENTIVE';
    const isWithdrawlType = ['WITHDRAW', 'BANK_TRANSFER_CREDIT'].includes(paymentType);

    const commonColorCss = 'text-scogogray';

    const getIncentiveDetails = () => {
        return (
            <>
                {isIncentivePaymentType && <p className={commonColorCss}>INCENTIVE</p>}
                {!isIncentivePaymentType && (
                    <>
                        {payment.reason && <span className='w-full flex items-center gap-2'>
                            <p className={commonColorCss}>Category:</p>
                            <OverflowTip someLongText={payment.reason} />
                        </span>}
                        {isWithdrawlType && <p className={commonColorCss}>WITHDRAW</p>}
                        <span className='w-full flex items-center gap-2'>
                            <p className={commonColorCss}>Remark:</p>
                            <OverflowTip someLongText={payment.txMsg} />
                        </span>
                        {payment.reason === 'INVOICE' && <span className='w-full flex items-center gap-2'>
                            <p className={commonColorCss}>Invoice:</p>
                            <OverflowTip someLongText={payment?.referenceId} />

                        </span>}
                    </>
                )}
            </>
        );
    };
    return (
        <div className='truncate w-full'>
            {basePaymentDetails && <p className={commonColorCss}>{basePaymentDetails}</p>}
            {!isBasePayment && getIncentiveDetails()}
        </div>
    );
};
