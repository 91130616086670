import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Message from '../messageBox/Messages/Message';
import DateDivider from './DateDivider';
import Loader from '../../../common/Loader';
import UnReadMessageCount from './UnreadMessageCount';
import { chatType } from '../ChatController';
import { GetQueryParam } from '../../../utils/common';
import { FormattedGptMessage } from '../messageBox/Messages/FormattedGptMessage';
import { useParams } from 'react-router-dom';
import Timestamp from '../messageBox/Messages/Timestamp';
import { Popover } from '@mui/material';
import FullScreenModal from '../../../common/Modals/FullScreenModal';
import axios from 'axios';
import devConfig from '../../../config/env/development';
import config from '../../../config';


export default function MessagesList({ openedConversationMessages, unReadMessageId, openedConversation, onClickReplyMessage, onReplyClick, replyToMessage, replyShow, showChatSubHeader, firstMessageRef, scrollDivRef, isCurrentChatLoading, handleScroll, isChatPopup, isChatMinimized = false, }) {
    const { chatId } = useParams();
    const { loggedUser } = useSelector((state) => state.auth);
    const { siaChatMessages, } = useSelector((state) => state.conversations)

    const [showIframe, setShowIframe] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [likes, setLikes] = useState({});
    const [isIframeLoading, setIsIframeLoading] = useState(true);

    const handleClick = (event) => {
        // setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    let isLastAi = siaChatMessages.length > 0 && siaChatMessages[siaChatMessages.length - 1].is_agent
    const chartClick = (event) => {
        // setAnchorEl(event.currentTarget);
        setIsIframeLoading(true);
        setShowIframe(true);

    };
    const isDateAreSame = (a, b) => {
        return a && b && moment(a).format('dd/MM/Y') === moment(b).format('dd/MM/Y');
    };
    const chatGptQueryParams = GetQueryParam('chatGpt', Boolean);

    const handleLike = async (messageId) => {
        try {
            setLikes(prev => ({
                ...prev,
                [messageId]: 'like'
            }));
            let url
            if (devConfig.name === config.name) {
                url = 'https://pyapi.development.scogo.ai/agents/extractor-kyc';
            } else {
                url = 'https://pyapi.scogo.com/agents/extractor-kyc';
            }
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    feedback_type: true,
                    message_id: messageId,
                }),
            });

            // Parse the response
            const data = await response.json();

            if (!response.ok) {  // Changed from response.data.success
                console.error('Failed to submit feedback');
                setLikes(prev => ({
                    ...prev,
                    [messageId]: undefined
                }));
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setLikes(prev => ({
                ...prev,
                [messageId]: undefined
            }));
        }
    };

    const handleDislike = async (messageId) => {

        try {
            setLikes(prev => ({
                ...prev,
                [messageId]: 'dislike'
            }));
            let url
            if (devConfig.name === config.name) {
                url = 'https://pyapi.development.scogo.ai/agents/extractor-kyc';
            } else {
                url = 'https://pyapi.scogo.com/agents/extractor-kyc';
            }
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    feedback_type: false,
                    message_id: messageId,
                }),
            });

            // Parse the response
            const data = await response.json();

            if (!response.ok) {  // Changed from response.data.success
                console.error('Failed to submit feedback');
                setLikes(prev => ({
                    ...prev,
                    [messageId]: undefined
                }));
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setLikes(prev => ({
                ...prev,
                [messageId]: undefined
            }));
        }
    };

    return <div ref={scrollDivRef} className={`${isChatMinimized ? 'pointer-events-none' : ''} relative bg-scogoeee overflow-y-auto flex-auto overflow-x-hidden`} onScroll={handleScroll}>
        <div className='absolute top-4 left-0 right-0 z-10 '>{isCurrentChatLoading && <Loader color='#F48A21' size='25' speed='1' />}</div>
        <div
            ref={firstMessageRef}
            style={{ left: '50%', bottom: '50%', transform: 'translate(-50%, -50%)' }}
            className={`rounded-md ${isChatPopup ? 'px-1 py-1' : 'px-2 py-2'} px-1 py-1 justify-center text-scogogray sticky top-8 z-10  max-w-max bg-tagmsg text-lg`}
        >
        </div>
        <div className='px-p-15'>
            {chatGptQueryParams ? <div>{siaChatMessages?.map((msg, index) => {
                const isAgent = msg?.is_agent;
                const hasFileUrl = msg?.metadata?.file_url;
                const hasPlotUrl = msg?.metadata?.plot_url;

                if (isAgent) {
                    return (
                        <div className='flex gap-2 py-2'>
                            <div className="flex justify-start py-2 gap-2" style={{ maxWidth: "65%" }}>
                                <span className="bg-mesColor rounded-md p-3 text-lg flex gap-4 flex-col">
                                    <FormattedGptMessage message={msg?.data} />
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-4">
                                            {hasFileUrl && (
                                                <div className="flex justify-start text-scogoorange text-font13">
                                                    <a href={msg.metadata.file_url}>Click Here to Download CSV</a>
                                                </div>
                                            )}
                                            {hasPlotUrl && (
                                                <div className="flex justify-start font-medium border-1 border-scogoprimary text-scogoprimary text-font12 px-1 rounded-md cursor-pointer hover:bg-scogoprimary hover:text-white" onClick={handleClick}>
                                                    <div id={'chartPopover'} onClick={chartClick}>View Chart</div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <Popover
                                        id={'chartPopover'}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <div className='flex flex-col gap-2 p-3'>
                                            <div className='flex gap-2 items-center'><span class="material-icons-outlined text-scogoorange text-font15">
                                                pie_chart
                                            </span> <span className='text-font13 cursor-pointer hover:underline'>Pie Chart</span></div>
                                            <div className='flex gap-2 items-center'> <span class="material-icons-outlined text-scogoorange text-font15">
                                                show_chart
                                            </span> <span className='text-font13 cursor-pointer hover:underline'>Line Graph</span></div>
                                            <div className='flex gap-2 items-center'> <span class="material-icons-outlined text-scogoorange text-font15">
                                                insert_chart_outlined
                                            </span> <span className='text-font13 cursor-pointer hover:underline'>Bar Chart</span></div>
                                        </div>
                                    </Popover>
                                    <div className="flex items-center gap-2 justify-between">
                                        {msg?.message_id && <div className="flex items-center gap-2">
                                            <button
                                                className={`flex items-center gap-1 transition-colors ${likes[msg?.message_id] === 'like'
                                                    ? 'text-scogoorange'
                                                    : 'text-gray-500 hover:text-scogoprimary'
                                                    }`}
                                                onClick={() => handleLike(msg?.message_id)}
                                            >
                                                <span className="material-icons-outlined text-2xl">
                                                    {likes[msg?.message_id] === 'like' ? 'thumb_up' : 'thumb_up_off_alt'}
                                                </span>
                                            </button>
                                            <button
                                                className={`flex items-center gap-1 transition-colors ${likes[msg?.message_id] === 'dislike'
                                                    ? 'text-red-500'
                                                    : 'text-gray-500 hover:text-scogoprimary'
                                                    }`}
                                                onClick={() => handleDislike(msg?.message_id)}
                                            >
                                                <span className="material-icons-outlined text-2xl">
                                                    {likes[msg?.message_id] === 'dislike' ? 'thumb_down' : 'thumb_down_off_alt'}
                                                </span>
                                            </button>
                                        </div>}

                                        <Timestamp timestamp={msg.created_at} className='text-right cursor-pointer' />
                                    </div>
                                    {showIframe && (
                                        <div className="fixed inset-0 top-5 w-screen h-screen bg-black/10 z-[9999] flex flex-col items-center justify-center">
                                            <div className="relative w-[95%] h-[90%] bg-white/95 backdrop-blur-sm rounded-lg p-4 shadow-xl">
                                                <div className="absolute bg-white/90 mt-3 top-8 border-2 border-scogoprimary rounded-full right-4 z-[999999] flex items-center gap-2">
                                                    <button
                                                        onClick={() => setShowIframe(false)}
                                                        className="flex items-center p-1 text-scogoprimary rounded cursor-pointer z-[999999]"
                                                    >
                                                        <span className="material-icons-outlined text-[24px] z-[999999]">
                                                            close
                                                        </span>
                                                    </button>
                                                </div>

                                                {isIframeLoading && (
                                                    <div className="absolute inset-0 flex items-center justify-center bg-white/50 backdrop-blur-sm">
                                                        <div className="flex flex-col items-center gap-3">
                                                            <div className="w-12 h-12 border-4 border-scogoorange border-t-transparent rounded-full animate-spin"></div>
                                                            <span className="text-scogoprimary font-medium">Loading chart...</span>
                                                        </div>
                                                    </div>
                                                )}

                                                <iframe
                                                    src={msg?.metadata?.plot_url}
                                                    title="Embedded HTML"
                                                    className="w-full h-full border-none rounded"
                                                    onLoad={() => setIsIframeLoading(false)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </span>
                            </div>
                        </div>
                    );
                }

                return (
                    <div className="flex justify-end w-full py-2  ">
                        <div className=" rounded-md text-xl px-2 pt-2 w-auto max-w-[50%] bg-white">
                            <p> {msg?.data}</p>

                            <Timestamp timestamp={msg?.created_at} className='text-right cursor-pointer' />
                        </div>
                    </div>
                );

            })
            }
                {!isLastAi && < RenderLoader />}
            </div> :

                <>   {openedConversationMessages?.map((msg, index) => {
                    let isDateChanged = true;

                    if (msg.timestamp && openedConversationMessages[index - 1] && openedConversationMessages[index - 1].timestamp) {
                        isDateChanged = !isDateAreSame(msg.timestamp, openedConversationMessages[index - 1].timestamp);
                    }

                    return (
                        <React.Fragment key={msg._id}>
                            {isDateChanged && <DateDivider timestamp={msg.timestamp} isChatPopup={isChatPopup} timeWidth={'w-full'} />}
                            {unReadMessageId === msg._id && <UnReadMessageCount unReadCount={openedConversation?.unreadMessageCount} isChatPopup={isChatPopup} />}
                            <Message
                                message={msg}
                                createdBy={openedConversation?.createdBy}
                                side={loggedUser.id === msg.from.userId ? 'right' : 'left'}
                                type={msg.type}
                                onClickReplyMessage={onClickReplyMessage}
                                onReplyClick={onReplyClick}
                                selectedMessageId={replyToMessage?._id}
                                replyShow={replyShow}
                                showMessageAction={openedConversation?.active && !openedConversation?.isBotEnabled}
                                toEnableChip={
                                    index === openedConversationMessages.length - 1 &&
                                    openedConversation?.active &&
                                    loggedUser?.id === openedConversation?.createdBy?.userId
                                }
                                showReplyIcon={openedConversation?.active && !openedConversation?.isBotEnabled && !openedConversation?.hideInput}
                                callStepFields={showChatSubHeader}
                                isChatPopup={isChatPopup}
                                isTicketChat={openedConversation?.type === chatType.ticket}
                            />
                        </React.Fragment>
                    );
                })}</>}
        </div>


        <div id='last_element_after_messages'></div>
    </div>;
};




const RenderLoader = () => {

    return (
        <div style={{ marginTop: '0.5rem' }} className={'messageWrapper'}>
            {/* <div className={'botIcon flex items-end'}>
                    <img
                        src="https://media.scogo.ai/media/chat-widget/assets/sia_white.png"
                        alt="Bot Icon"
                        width={28}
                        height={28}
                    />
                </div> */}
            <div className={'shiny-text pl-2'}>
                Thinking ...
            </div>
        </div >
    );

};

